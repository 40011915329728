.RichEditor-root {
  background: #fff;
  font-size: 14px;
  padding: 15px 15px 0 15px;
  border-radius: 6px;
  border: 1px solid #d1d0d1;
  color: black;
  min-height: 400px;
  position: relative;
}

.RichEditor-root.read-only {
  border: none !important;
  padding: 0;
  min-height: 0;
}

.RichEditor-root.read-only .RichEditor-editor {
  border: 0;
  margin: 0;
  height: inherit;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  height: 100%;
  position: relative;
}

.RichEditor-editor a {
  color: #5e93c5;
  text-decoration: none;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
  position: relative;
}

.RichEditor-root.read-only .RichEditor-editor .public-DraftEditor-content {
  padding: 0px 70px 0px 15px;
  min-height: 0px;
  height: auto;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  max-height: 25px;
  user-select: none;
}

.RichEditor-styleButton {
  cursor: pointer;
  margin-right: 6px;
  padding: 2px 0;
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  position: relative;
}

.RichEditor-styleButton:hover {
  background-color: #f7f7f7;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
  position: relative;
}

.RichEditor-activeButton {
  background-color: #f7f7f7;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
}

.public-DraftStyleDefault-block {
  font-size: 14px;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding-inline-start: 10px;
}

.DraftEditor-editorContainer {
  height: 326px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.RichEditor-root.read-only .DraftEditor-editorContainer {
  min-height: 50px;
  height: auto;
}

.ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.link-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.inline-toolbar {
  position: absolute;
  top: 0;
  background: white;
  z-index: 1000;
}

.button-wrapper svg {
  margin-bottom: -3px;
  margin-top: 3px;
}

.inline-toolbar input.link-input-error {
  border: 1px solid red;
}

.RichEditor-root.read-only .RichEditor-editor .inline-toolbar {
  display: none;
}
