.search-filters {
  position: sticky !important;
  top: 106px;
  width: 100%;
  padding: 24px;
}

.search-filters .company-score {
  border: 0 none;
}
