.header__quick-link {
  font-size: 13px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-weight: 400 !important;
}

.header__section-header {
  padding-top: 50px;
  left: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .header__section-header {
    padding-top: 30px;
    left: 0px;
    font-weight: 500;
  }
}
