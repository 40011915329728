/*--------------------------------------------------------------
# App
--------------------------------------------------------------*/
.app-wrapper {
  display: block;
  position: relative;
  height: 100vh;
}

.content-wrapper {
  padding-left: 14%;
  height: 100%;
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1600px;
  }
}

/*--------------------------------------------------------------
# Body
--------------------------------------------------------------*/
body {
  background-color: #f7f7f7;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #000;
}

/*--------------------------------------------------------------
# Bootstrap override
--------------------------------------------------------------*/

.form-control:focus {
  /*box-shadow: initial;*/
  border-color: #d1d0d1;
}

.input-field,
.form-control {
  border: 1px solid #d1d0d1 !important;
  border-radius: 6px !important;
}

.form-control,
.input-field,
.btn:focus,
*:focus {
  outline: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.table > thead > tr > th {
  vertical-align: middle;
  border-bottom: 0 none;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.table > tbody > tr > td {
  border-top: 0 none;
  border-bottom: 1px solid #ededed;
  padding: 18px 8px;
  font-size: 13px;
  vertical-align: middle;
}

.table > tbody > tr:last-child > td {
  border-bottom: 0 none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

button:active,
button:focus,
button {
  outline: none;
}

.ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-color: #d1d0d1 !important;
}

.ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-color: #d1d0d1 !important;
}

.tv-select-container {
  border: 1px solid #d1d0d1;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  height: 44px;
  position: relative;
  background: #f5f5f5;
  cursor: pointer;
}

.tv-select-container select {
  height: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.tv-select-container select:focus {
  outline: none;
}

.tv-select-arrow {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 20px;
  border-left: 1px solid #d1d0d1;
  color: #999;
  z-index: 0;
}

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/

.tv-modal {
  z-index: 10000;
}

.modal-backdrop.fade {
  opacity: 1;
  background-color: rgba(81, 81, 81, 0.2);
}

.tv-modal .modal-dialog {
}

.tv-modal .modal-header {
  display: block;
  text-align: left;
  padding: 18px 20px 18px 20px;
  min-height: 40px;
  border-bottom: 1px solid #dcdcdc33;
}

.tv-modal .modal-close {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #d4d4d4;
  font-size: 21px;
  background: #fff;
  border: 0;
}

.tv-modal .modal-close:hover {
  background: transparent;
  border-color: transparent;
}

.tv-modal .modal-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.tv-modal .modal-body {
  padding: 20px;
}

.tv-modal .modal-body .vertical-item {
  margin-bottom: 20px;
}

.modal-body .subvertical-item-add input {
  float: left;
  width: 75%;
  overflow: hidden;
}

.modal-body .subvertical-item-add button {
  float: right;
  margin-top: 8px;
  width: 20%;
}

.modal-body .input-field,
.modal-body .form-control {
  width: 100%;
  height: 44px;
}

.modal-body textarea.form-control {
  height: 75px;
}

.modal-body p {
  margin-bottom: 5px;
}

.modal-body .modal-row {
  margin-bottom: 10px;
}

.tv-modal .modal-content {
  border-radius: 8px;
  border: 0 none;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
}

.tv-modal .modal-footer {
  border: 0 none;
  background: rgba(220, 220, 220, 0.2);
  padding: 18px 20px 18px 20px;
}

.tv-modal .tv-button {
  float: none;
  display: block;
  margin: 0 auto;
}

.tv-modal.tv-text-editor-modal .modal-dialog {
  min-width: 700px;
}

.tv-company-modal li {
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 300;
  color: #262626;
}

.tv-company-modal li.form-checkbox-row {
  padding: 14px 0;
  margin: 0;
  border-bottom: 1px solid #eee;
}

.tv-company-modal li.form-section-header,
.tv-company-modal li.form-section-header label {
  margin-bottom: 0;
}

.tv-company-modal .modal-dialog {
  max-width: 400px;
}

.tv-company-modal label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}

.tv-company-modal .form-check-input {
  margin-right: 12px;
}

.tv-company-modal li:first-child {
  padding-top: 0;
}

.tv-company-modal li:last-child {
  padding-bottom: 0;
  border-bottom: 0 none;
}

.tv-company-modal .form-check-label {
  /* padding-left: 0; */
  position: relative;
}

.form-checkbox-row .form-check-label {
  padding-left: 20px;
}

.form-checkbox-row input {
  visibility: hidden;
}

.form-checkbox-row .form-check-label i {
  color: #9b9b9b;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 2px;
}

.form-check-label > input + i.unchecked {
  display: block;
}
.form-check-label > i.checked {
  display: none;
}

.form-check-label > input:checked + i.unchecked {
  display: none;
}
.form-check-label > input:checked + i.unchecked + i.checked {
  display: block;
}

.tv-company-modal .form-check {
  margin-bottom: 0;
}

.tv-company-modal li.team-checkbox-row {
  float: left;
  margin: 10px 12px 0 0;
  overflow: hidden;
}

.team-members-title {
  margin-top: 30px;
  margin-bottom: 15px;
}

.team-checkbox {
  width: 30px;
  height: 30px;
  display: block;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
}

.team-checkbox-row label {
  width: 100%;
  display: block;
}

.team-checkbox-row input {
  visibility: hidden;
}

.team-checkbox-row span {
  display: block;
  line-height: 30px;
  text-align: center;
}

.team-checkbox-row input:checked + span {
  background-color: rgba(220, 220, 220, 0.2);
}

.tv-linkedin-signal-modal h5 {
  font-size: 16px;
  padding-bottom: 4px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f7f7f7;
}

.tv-linkedin-signal-modal h5.last-header {
  margin-top: 50px;
}

.tv-linkedin-signal-modal a {
  display: block;
  margin-bottom: 14px;
}

.tv-linkedin-signal-modal a span {
  display: block;
}

.tv-linkedin-signal-modal a span.linkedin-name {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1em;
}

.tv-linkedin-signal-modal a span.linkedin-headline {
  font-size: 13px;
  color: #787878;
}

@media only screen and (max-width: 720px) {
  .tv-modal.tv-text-editor-modal .modal-dialog {
    min-width: 100%;
  }

  .tv-modal .modal-dialog {
    margin: 10px auto;
  }

  .tv-company-modal .modal-dialog {
    max-width: unset;
    width: 96%;
  }
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/

/* #root { */
/* margin-bottom: 60px; */
/* } */

.row:before,
.row:after {
  display: inline-block;
}

.content {
  margin-bottom: 40px;
}

.sidebar-container {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ebeaea;
  overflow: hidden;
}

.sidebar-container .widget-row-container:last-child {
  border: 0;
}

.content-container {
  background-color: #fff;
  border: 1px solid #ebeaea;
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  display: block;
  margin-bottom: 20px;
}

.content-container.single-document {
  padding: 0;
  border: none;
  width: 100%;
  margin-bottom: 0;
}

.content-container.single-document ul {
  margin: 0;
}

.content-container.investor-verticals .vertical-item {
  margin-bottom: 8px;
}

.container-header h2 {
  margin-bottom: 0;
  font-size: 22px;
}

.card-block {
  padding: 0;
}

.card-block h6 {
  font-weight: 500;
}

.card-actions {
  float: right;
}

.container-page-header {
  width: 100%;
  background-color: #fff;
  padding: 25px 50px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  position: sticky;
  top: 0;
  z-index: 101;
}

.page-header {
  border: 0 none;
  margin: 0 auto;
  padding-bottom: 0;
}

.container-page-header h2 {
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  margin-right: 40px;
  margin-bottom: 0;
  color: #000;
}

.page-header .btn-group {
  float: left;
}

.page-header .list-actions {
  float: right;
  margin-top: -5px;
}

.list-name-description {
  float: left;
  max-width: 400px;
}

.list-name-description h2,
.list-name-description span {
  display: block;
  float: none;
}

.toggle-view-btns {
  border: 1px solid #ebeaea;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 4px;
  width: 180px;
}

.toggle-view-btns .btn {
  border: 0 none;
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: 400;
  width: 50%;
  line-height: 1;
}

.toggle-view-btns .btn.active {
  border: 0;
  background-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  font-weight: 500;
  color: #333;
  border-radius: 4px !important;
}

.vertical-filters {
  background: #fff;
  height: 60px;
  margin-top: 20px;
  padding: 0 30px;
  display: flex;
  grid-template-columns: 75px repeat(6, 50px);
  border: 1px solid #ebeaea;
  border-radius: 4px;
  align-items: center;
  flex-wrap: wrap;
}

.vertical-filters h6 {
  margin: 0px 10px 0px 0px;
  color: #6e6e6e;
  font-weight: 500;
  position: relative;
  line-height: 1;
  font-size: 12px;
}

.filter-btn {
  text-align: center;
  background: #ffffff;
  margin: 0 3px !important;
  padding: 4px 8px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  outline: none !important;
  min-width: 50px;
  height: 30px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 #ddd;
  border: 0.5px solid #ddd;
  transition: 200ms all;
}

.filter-btn:first-child {
  margin-left: 0 !important;
}

.filter-btn:hover {
  text-decoration: none;
  background-color: #f2f2f2;
}

.filter-btn.active {
  background: #f2f2f2;
}

@media only screen and (max-width: 991px) {
  .page-header .list-actions {
    float: none;
    padding: 20px 0 0;
    margin: 0;
    display: block;
    width: 100%;
    clear: both;
  }

  .list-name-description {
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.nav {
  display: block;
  width: 100%;
}

.navigation-container {
  height: 100%;
  width: 14%;
  padding: 0 10px;
  position: fixed;
  background-color: #fff;
  border-right: 1px solid rgba(153, 153, 153, 0.2);
}

.navbar {
  height: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
}

.navbar-header {
  float: none;
  margin: 14px 0 20px 15px;
}

.navbar-brand {
  line-height: 56px;
  padding: 0;
  color: #d48b07;
  font-size: 21px;
  margin-right: 60px;
  font-weight: 400;
}

a.navbar-brand:not([href]):not([tabindex]) {
  color: #d48b07;
}

a.navbar-brand:hover {
  color: #ffa300;
}

.navbar-brand i {
  font-style: normal;
  font-weight: 900;
}

.navbar-nav {
  height: 87%;
  display: flex;
  flex-direction: column;
}

.navbar-nav > :last-child {
  position: absolute;
  bottom: 10px;
}

.navbar-nav > li {
  display: block;
  float: none;
  margin: 5px 0;
}

.navbar-nav > li > button,
.navbar-nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  float: none;
  font-size: 14px;
}

.navbar-nav > li > a:hover {
  color: inherit;
  background-color: #f7f7f7;
  border-radius: 3px;
}

.navbar-nav > li > button {
  background: transparent;
  border: 0 none;
}

.navbar-nav .logout-button {
  cursor: pointer;
  font-weight: normal;
  font-size: 12px;
}

.nav-search-mobile {
  display: none;
}

.navbar-nav > li.nav-search {
  padding: 0 15px;
  margin: 30px 0 0;
}

.nav-search input[type='text'] {
  margin: 0;
  outline-color: #ffa300;
  width: 100%;
  border-radius: 4px !important;
}

.nav-search input[type='text']:focus {
}

.navbar .container {
}

.mobile-selector {
  display: none;
  line-height: 30px;
  padding: 15px;
  margin-bottom: 0;
  border-bottom: 2px solid #eee;
  background: #f7f7f7;
  position: sticky;
  top: 105px;
  z-index: 1000;
}

.mobile-selector ul {
  list-style: none;
  width: 100%;
  margin: 0;
  height: 30px;
  display: block;
  padding: 0 0 0 10px;
}

.mobile-selector li {
  float: left;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: -2px;
  position: relative;
}

.mobile-selector button {
  border: 0 none;
  background: transparent;
  padding: 0;
  outline: none !important;
}

.mobile-selector li.active {
  font-weight: 500;
}

.mobile-selector li.active:after {
  content: ' ';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  background-color: #333;
  left: 0;
  z-index: 999;
}

@media only screen and (max-width: 991px) {
  .mobile-selector {
    display: block;
  }

  .navbar {
    padding: 0;
    /*margin-bottom: 20px;*/
  }

  .navbar .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .navbar-header {
    text-align: center;
    margin: 0;
  }

  .navigation-container {
    height: 60px;
    width: 100%;
    padding: 0;
    border-right: 0;
    position: fixed;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    z-index: 10000;
  }

  .container-page-header {
    height: 50px;
    width: 100%;
    background-color: #fff;
    padding: 10px 15px;
    overflow: hidden;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    position: sticky;
    top: 60px;
    z-index: 1000;
  }

  .container-page-header h2 {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    margin-right: 20px;
    margin-bottom: 0;
    color: #000;
  }

  .content-wrapper {
    padding-left: 0;
  }

  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin: 0;
  }

  .navbar-brand {
    margin: 0 auto;
    width: 100%;
  }

  .mobile-nav {
    position: relative;
    background-color: #fff;
    height: calc(100vh - 50px);
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    width: 100%;
    margin: 0 !important;
    display: none !important;
  }

  .toggled .mobile-nav {
    display: block !important;
  }

  .mobile-nav li {
    float: none;
    display: block;
    text-align: center;
  }

  .navbar-nav > :last-child {
    position: relative;
    margin-top: 50px;
  }

  .toggle-view-btns {
    display: block;
    margin-top: 10px !important;
    height: 50px !important;
    margin-bottom: 10px;
  }

  .toggle-view-btns .btn {
    font-size: 11px !important;
    min-height: 30px;
  }

  .flagged-btns {
    display: flex;
    flex-wrap: wrap;
    height: fit-content !important;
  }

  .vertical-filters {
    padding: 0 10px;
  }

  .menu-toggle {
    position: absolute;
    left: 0;
    border: 0 none;
    border-radius: 0;
    background: transparent;
    width: 44px;
    height: 44px;
    padding: 0;
    top: 3px;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .menu-icon {
    width: 25px;
    height: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
  }

  .menu-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #ff8401;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .menu-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .menu-icon span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .menu-icon span:nth-child(3) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .toggled .menu-icon span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -4px;
    left: 2px;
  }

  .toggled .menu-icon span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .toggled .menu-icon span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 13px;
    left: 2px;
  }

  .search-toggle {
    position: absolute;
    right: 0;
    border: 0 none;
    border-radius: 0;
    background: transparent;
    width: 44px;
    height: 44px;
    padding: 0;
    top: 3px;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  .search-toggle i {
    color: #ff8401;
    font-size: 23px;
    display: block;
  }

  .nav-search-mobile {
    display: none;
    width: 100%;
    height: 50px;
    background-color: #333;
    position: absolute;
    padding: 5px;
  }

  .nav-search-mobile.toggled {
    display: block;
  }

  .nav-search-mobile input[type='text'] {
    height: 40px;
  }
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/

.navbar-brand.login {
  text-align: center;
  float: none;
  display: block;
  margin: 0 0 12px;
  font-size: 30px;
}

.app-login-form .btn {
  width: 100px;
  border-radius: 3px;
}

/*--------------------------------------------------------------
# Search page
--------------------------------------------------------------*/
.search-container input {
  font-weight: 300;
  font-size: 18px;
  position: relative;
}

.search-container input:focus {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
}

/*--------------------------------------------------------------
# Flagged
--------------------------------------------------------------*/

.page-header .flagged-toggle-group {
  float: none;
}

.flagged-btns {
  width: 100%;
  height: 50px;
  background: #fff;
}

.flagged-btns .btn.active {
  background: #f2f2f2;
  font-weight: 500;
}

.flagged-btns .btn {
  font-size: 14px;
}

/*--------------------------------------------------------------
# Investor Search
--------------------------------------------------------------*/

.investor-btns {
  width: 320px;
  height: 40px;
  margin-top: 0;
  background: #fff;
}

.investor-btns .btn.active {
  background: #e6e6e6;
  font-weight: 600;
}

.investor-btns .btn {
  font-size: 12px;
}

/*--------------------------------------------------------------
# Investor Profile
--------------------------------------------------------------*/

.investor-profile .company-feed {
  position: absolute;
  right: 15px;
  padding-right: 50px;
}

.investor-profile .toggle-view-btns {
  float: left;
}

.investor-profile .toggle-view-btns {
  background: #fff;
  width: 250px;
  height: 40px;
}

.investor-profile .toggle-view-btns.is-mobile {
  width: 100%;
}

.investor-profile .toggle-view-btns .btn.active {
  background: #f2f2f2;
}

.investor-profile .list-vertical-col {
  width: 12%;
}

.investor-profile .investor-list .list-stage-col {
  width: 15%;
}

.investor-profile .investor-list .list-date-col {
  width: 15%;
}

.investor-profile .company-feed .feed-actions {
  position: relative;
}

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/

.lists-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #898989;
}

.list-card {
  padding: 0 40px;
  transition: 300ms all;
}

.list-card:hover {
  background-color: rgba(220, 220, 220, 0.2);
}

.list-card::before {
  content: ' ';
  width: 7px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #9b9b9b;
  opacity: 0;
  transition: 300ms all;
}

.list-card:hover::before {
  opacity: 1;
}

.list-card h2 {
  margin-bottom: 0;
  float: left;
  line-height: 70px;
  font-size: 20px;
  width: calc(100% - 250px);
}

.list-profile-head h2 {
  float: left;
  line-height: 30px;
}

.list-card:hover h2 {
  color: inherit;
  color: #333;
}

.list-card .list-actions {
  line-height: 70px;
  float: right;
}

.list-profile-head .list-actions {
  float: right;
  line-height: 30px;
}

.list-meta {
  margin-right: 50px;
  color: #6a6a6a;
  font-size: 12px;
  display: flex;
}

.list-meta-item {
  text-align: center;
  display: inline-block;
  margin: 0 11px;
}

.list-meta-item strong {
  color: #000;
  line-height: 1.1em;
  font-size: 24px;
  font-weight: 500;
  display: block;
}

.list-profile-head .list-meta {
  margin: 0;
}

.list-meta strong {
  font-weight: 600;
}

.list-actions i {
  font-style: normal;
  padding: 0 6px;
  color: #ddd;
}

@media only screen and (max-width: 991px) {
  .list-card {
    padding: 20px;
  }
  .list-profile-head h2,
  .list-card h2 {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    float: none;
    display: block;
    margin-bottom: 20px;
  }

  .list-profile-head h2 {
    margin-bottom: 6px;
    font-size: 21px;
  }

  .list-profile-head .list-actions,
  .list-card .list-actions {
    float: none;
    display: block;
    line-height: normal;
  }
}

/*--------------------------------------------------------------
# List page
--------------------------------------------------------------*/

.search-container .container,
.company-panel-list {
  overflow-x: scroll;
  clear: both;
}

.company-panel-feed {
  width: 100%;
}

.user-feed .company-feed-item-container {
  padding: 24px 28px 24px 34px;
}

.search-container .list-table,
.company-panel-list:not(.company-panel-feed) .list-table {
  margin-bottom: 0;
  margin-top: 20px;
  min-width: 1000px;
  overflow-x: scroll;
  border: 1px solid #ebeaea;
}

.company-panel-feed .list-table {
  margin-bottom: 0;
  margin-top: 40px;
  border: 1px solid #ebeaea;
  background: #fff;
  /* border-radius: 4px; */
  box-shadow: 0 2px 1px 0 rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
}

.company-panel-feed .list-table.empty {
  margin-top: 20px;
}

.investor-list .list-table {
  margin-top: 0;
}

.dashboard-container .list-table {
  margin: 0;
}

.company-feed .list-table {
  margin-top: 0;
}

.company-feed .company-panel-list .list-table {
  max-width: 940px;
}

.company-panel-list .list-table {
  width: 100%;
}

.section-header {
  margin: 30px 0 15px;
  /* color: #505050; */
  font-size: 18px;
  line-height: 24px;
}

.section-header i {
  font-size: 14px;
  vertical-align: middle;
  margin-right: 8px;
}

.list-description-col {
  width: 17%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4em !important;
  max-height: 2.8em;
  font-size: 12px !important;
}

.prospect-list .list-description-col {
  width: 19%;
  padding-right: 25px;
}

.investor-list .list-description-col {
  width: 29%;
  padding-right: 5%;
}

.list-score-col,
.list-investor_score-col {
  width: 6%;
}

/* .list-score-col */
.green-score {
  color: #6eb41e;
  font-weight: 800;
}

/* same styling as the Card component */
.tv-card {
  background-color: #fff !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
  position: relative;
  font-weight: 500;
  border: 1px solid #ebeaea !important;
}

.list-notes-col {
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 1.2em !important;
  max-height: 1.2em;
}

.list-notes-col ul,
.list-notes-col ol {
  margin: 0;
  padding: 0;
}

.list-name-col {
  width: 11%;
  max-height: 2.4em !important;
  line-height: 1.2em !important;
  overflow: hidden;
}

.investor-search .list-name-col {
  width: 14%;
}

.investor-search .flagged-btns {
  position: sticky;
  z-index: 100;
  top: 106px;
}

.investor-search .col-md-9 {
  position: relative;
  height: 100%;
}

.investor-search .col-md-3 {
  position: relative;
}

.list-relationship-col {
  width: 10%;
}

.list-tier-col {
  width: 8%;
}

.list-vertical-col {
  width: 8%;
}

.list-vertical-col .vertical-item {
  margin-right: 4px;
}

.list-subvertical-col {
  width: 12%;
}

.list-investors-col {
  width: 22%;
}

.list-investors-col .status-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.investor-search .list-vertical-col {
  width: 12%;
}

.investor-search .list-stage-col {
  width: 14%;
}

.list-company-col.list-name-col {
  font-weight: 600;
  font-size: 13px;
}

.non-prospect .list-description-col {
}

.list-owner-col {
  width: 6%;
}

.list-tier-col span,
.list-relationship-col span,
.list-owner-col span {
  font-size: 11px;
  height: 25px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 4px;
  line-height: 25px;
  padding: 7px 12px;
}

.list-location-col {
  width: 13%;
}

.list-myMemos-col {
  width: 14%;
}

.list-fundDate-col {
  width: 11%;
}

.list-stage-col,
.list-outstanding-col,
.list-score-col {
  width: 5%;
}

.investor-search .list-score-col {
  width: 8%;
}

.list-stage-col {
  width: 8%;
}

.list-status-col {
  width: 10%;
  display: flex;
  justify-content: flex-start;
}

.investor-search .list-status-col {
  width: 12%;
}

.list-status-col .status-text {
  position: relative;
  font-weight: 600;
}

.list-status-col .status-text:before {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  left: 0;
  content: ' ';
  top: 0;
  bottom: 0;
  margin: auto;
}

.list-status-col .status-text.review:before {
  background: #f3a63b;
}

.list-status-col .status-text.passed:before {
  background: #6eb41e;
}

.list-daysOutstanding-col,
.list-employeeGrowth-col {
  width: 7%;
}

.list-monthsSinceInvestment-col {
  width: 8%;
}

.investor-list .list-stage-col {
  width: 15%;
}

/* .list-outstanding-col */
.list-company-name {
  font-weight: 500;
}

.list-company-col,
.list-col,
.list-col-header {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: normal;
  font-size: 12px;
}

.company-panel-feed .list-col,
.company-panel-feed .list-company-col,
.company-panel-feed .list-col-header {
  vertical-align: top;
}

.list-col-header {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #807f80 !important;
  vertical-align: middle !important;
  position: relative;
}

.list-col-header.sorting::after {
  content: ' ';
  position: absolute;
  background-image: url('../images/sort-caret.png');
  width: 8px;
  height: 5px;
  background-size: cover;
  margin-left: 4px;
  top: 4px;
}

.list-col-header.sorting.asc::after {
  transform: rotate(180deg);
}

.company-feed .list-header {
  display: none;
}

.company-panel-list .list-header span:hover {
  cursor: pointer;
}

.company-panel-feed .list-company-row {
  line-height: 1em;
  padding-top: 20px;
  padding-bottom: 20px;
  height: inherit;
}

.company-feed .list-company-row {
  padding-left: 30px;
}

.has-update:after {
  width: 3px;
  height: calc(100% - 16px);
  position: absolute;
  background: #b8e986;
  left: 8px;
  top: 8px;
  content: '';
}

.list-type-col {
  width: 10%;
}

.company-feed .list-type-col {
  width: 15%;
}

.list-type {
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03);
  padding: 0 15px;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  width: 65px;
  text-align: center;
}

.list-type.status {
  background-color: #707a91;
}
.list-type.funding {
  background-color: #81a1c1;
}
.list-type.note {
  background-color: #a2be8c;
}
.list-type.news {
  background-color: #ebcb8b;
}
.list-type.list {
  background-color: #b48ead;
}
.list-type.task {
  background-color: #bf616a;
}

.list-co-col {
  width: 15%;
  font-weight: 500;
  font-size: 13px;
}

.list-update-col {
  width: 60%;
  color: #5a5a5a;
  font-size: 12px;
}

.list-update-col .item-header {
  font-size: 13px;
  margin: 0;
  font-weight: 500;
  display: block;
}

.item-task-description {
  display: block;
  margin-top: 5px;
}

.full-description {
  display: none;
}

.company-feed .list-update-col {
  width: 67%;
}

.list-date-col {
  width: 15%;
  font-size: 13px;
  color: #000;
}

.company-panel-feed .list-date-col {
  width: 15%;
  padding-left: 4%;
}

.company-feed .list-date-col {
  width: 18%;
}

.vertical-expertise.company-score {
  padding: 8px 0;
}

.vertical-expertise .score {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.vertical-item {
  flex-grow: 1;
  min-height: 28px;
  line-height: 24px;
  padding: 0 7px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 11px;
  background-color: #fff;
  margin-right: 3px;
  margin-bottom: 3px;
  border: 1px solid #ddd;
  min-width: 28px;
  align-items: center;
  justify-content: center;
}

.vertical-item.small {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 10px;
  min-height: 20px;
  min-width: 20px;
}

.company-profile .vertical-item {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.company-profile .vertical-item.small {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 10px;
  min-height: 20px;
  min-width: 20px;
}

.advanced-search .vertical-item {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.tv-modal.advanced-search .vertical-item {
  margin-bottom: 0;
}

.vertical-expertise .vertical-item {
  text-align: center;
  padding: 0 6px;
}

.vertical-expertise .score {
  margin: 0;
  padding: 0;
}

.vertical-expertise .selected {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  margin: 0;
}

.vertical-expertise .none {
  width: 88px;
  color: #000000;
}

.real-estate {
  background-color: #ffd2cf;
  border-color: #ffd2cf;
}
.re {
  background-color: #ffd2cf;
  border-color: #ffd2cf;
}
.aiml {
  background-color: #c2e2ff;
  border-color: #c2e2ff;
}
.ai {
  background-color: #c2e2ff;
  border-color: #c2e2ff;
}
.cloud {
  background-color: #cdf7f5;
  border-color: #cdf7f5;
}
.cloud-infrastructure {
  background-color: #cdf7f5;
  border-color: #cdf7f5;
}
.insurtech {
  background-color: #f7f7f7dd;
}
.it {
  background-color: #f7f7f7dd;
}
.ci {
  background-color: #cdf7f5;
  border-color: #cdf7f5;
}
.fintech {
  background-color: #ffde8c;
  border-color: #ffde8c;
}
.financial-technology {
  background-color: #ffde8c;
  border-color: #ffde8c;
}
.ft {
  background-color: #ffde8c;
  border-color: #ffde8c;
}
.martech {
  background-color: #bdffd9;
  border-color: #bdffd9;
}
.marketing-technology {
  background-color: #bdffd9;
  border-color: #bdffd9;
}
.mt {
  background-color: #bdffd9;
  border-color: #bdffd9;
}
.security {
  background-color: #f6daf8;
  border-color: #f6daf8;
}
.cybersecurity {
  background-color: #f6daf8;
  border-color: #f6daf8;
}
.cs {
  background-color: #f6daf8;
  border-color: #f6daf8;
}
.empty {
  background-color: #fff;
  border-color: #ebeaea;
}

.subscribe-button-container {
  margin-left: 30px;
  float: right;
}

.subscribe-button-container button {
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .list-company-row {
    padding-left: 25px;
    padding-right: 15px;
  }
}

.list-panel-legend {
  width: 230px;
  float: left;
  margin-left: 30px;
  margin-top: 72px;
  background-color: #fff;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 14px;
}

.company-feed-legend {
  float: none;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin: 0;
  clear: both;
  overflow: hidden;
  padding: 5px 0 0 0;
}

.company-feed-legend ul li {
  margin-bottom: 0 !important;
}

.list-panel-legend label {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.list-panel-legend ul {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.list-panel-legend ul li {
  float: left;
  position: relative;
  width: 60px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  border-radius: 10px;
  border: 0.5px solid #969696;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.list-panel-legend ul li a {
  color: #787878;
  text-decoration: none;
  display: block;
}

.list-panel-legend ul li:hover,
.list-panel-legend ul li.active {
  background-color: #9b9b9b;
  text-decoration: none;
}
.list-panel-legend ul li:hover a,
.list-panel-legend ul li.active a {
  color: #fff;
}

/* Notes toggle */
.funding-description {
  margin-top: 5px;
  font-size: 14px;
}

.notes-container {
  padding: 14px;
  border: 1px solid #ebeaea;
  position: relative;
  background: #fff;
  border-radius: 4px;
}

.notes-container .notes-text-editor-container {
  height: auto;
  overflow: hidden;
  position: relative;
}

.notes-container .notes-text-editor-container.is-collapsed {
  height: 18px;
}

.notes-text {
  position: relative;
  font-size: 13px;
  color: #505050;
}

.notes-actions {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  height: 46px;
  background: #fff;
  z-index: 10;
  padding: 0 5px;
}

.notes-actions .icon-wrapper {
  margin-top: 3px;
}

.notes-actions .icon-wrapper:last-child {
  margin-left: 3px;
}

.notes-text p {
  margin: auto;
}

.toggle-button:hover,
.toggle-button:active {
  text-decoration: underline;
  color: #000;
}

.btn-edit-note {
  padding: 0;
  background: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  right: 35px;
  vertical-align: middle;
}

.btn-edit-note:hover {
  text-decoration: underline;
}

.notes-container.collapsed {
  /*max-height: 100px;*/
}

.notes-container.collapsed .notes-text {
  overflow: hidden;
  max-height: 50px;
}

.notes-container.collapsed .notes-text:before {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  height: 1.1em;
  bottom: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 255), rgba(255, 255, 255, 0.5));
  z-index: 1;
}

.list-company-row:hover .collapsed .notes-text:before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #f7f7f7 75%);
}

.dashboard-panel {
  width: 230px;
  float: left;
  margin: 30px 0 0 30px;
}

.dashboard-panel .section-header {
  margin: 0 0 10px;
}

.dashboard-panel-inner {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  padding: 15px 20px;
  position: relative;
  margin: 10px 0px;
}

.dashboard-panel-inner h6 {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.dashboard-panel-inner .panel-bottom {
  margin-top: 15px;
  bottom: 4px;
  padding-top: 8px;
  border-top: 1px solid #dbdbdb;
  width: 100%;
  overflow: hidden;
}

.panel-bottom label {
  font-weight: 300;
  color: #9b9b9b;
  font-size: 12px;
  width: 50%;
  margin: 0;
}

.panel-bottom .left-label {
  float: left;
}

.new-companies {
  color: #008000;
  display: inline-block;
}

.panel-bottom .right-label {
  float: right;
  text-align: right;
}

.dashboard-panel-tasks {
  margin-bottom: 25px;
}

.dashboard-panel-tasks .dashboard-panel-inner {
  height: auto;
}

.dashboard-panel-task {
  position: relative;
}

.dashboard-panel-task h6 {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
}

.dashboard-panel-task p {
  margin: 0;
  line-height: 1.4em;
  font-size: 13px;
}

.dashboard-panel-task button.read-more {
  font-size: 10px;
}

.dashboard-panel-task .panel-bottom {
  position: relative;
  border-top: 0 none;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  overflow: hidden;
  margin: 5px 0 15px;
  padding-bottom: 10px;
}

.dashboard-panel-task .left-label {
  margin-right: 5px;
}

.dashboard-panel-task .right-label,
.dashboard-panel-task .left-label {
  width: auto;
  float: left;
}

.dashboard-panel-task .list-type {
  width: auto;
}

.dashboard-panel-tasks .dashboard-panel-task:last-child .panel-bottom {
  border: 0 none;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .company-panel-feed {
    float: none;
  }

  .company-panel-feed .list-company-col {
    width: 100%;
    float: none;
    padding: 0;
    margin-bottom: 10px;
  }

  .company-panel-feed .list-date-col {
    padding-left: 0;
  }

  .company-panel-feed .list-type-col {
    margin-right: 10px;
  }

  .company-panel-feed .list-type-col,
  .company-panel-feed .list-co-col {
    float: left;
    width: auto;
    line-height: 20px;
  }

  .company-panel-feed .list-header {
    display: none;
  }

  .company-feed .feed-header {
    height: auto;
  }

  .company-feed .feed-actions {
    left: 0;
    right: auto;
    position: relative;
  }

  .company-feed .feed-actions button {
    margin-left: 0;
    margin-right: 8px;
  }

  .company-feed-legend {
    padding-top: 10px;
  }

  .list-panel-legend ul {
    float: none;
  }

  .list-panel-legend ul li {
    margin: 0 8px 8px 0 !important;
  }

  .dashboard-panel {
    float: none;
    margin-left: 0;
    margin-top: 50px;
    width: 100%;
  }
}

/*--------------------------------------------------------------
# PAGE
--------------------------------------------------------------*/

.page-container {
  padding: 30px 50px !important;
}

@media only screen and (max-width: 991px) {
  .page-container {
    padding: 0 15px !important;
  }
}

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/

.tv-button {
  line-height: 28px;
  height: 28px;
  display: inline-block;
  padding: 0 15px;
  font-size: 12px;
  transition: 200ms all;
  min-width: 70px;
  border: 0 none;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #323232;
  font-weight: 500;
  box-shadow: 0 1px 1px 0 #ddd;
  border: 0.5px solid #ddd;
  margin-top: 20px;
}

.tv-button:hover {
  text-decoration: none;
  background-color: #ddd;
}

.tv-button.subscribed {
  background-color: #ffff;
  border: 1px solid #f9d59a;
  /*background-color: rgba(245,166,35,0.2);*/
}

p.error {
  color: red;
}

button.read-more {
  font-size: 11px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  color: #787878;
}

div.feed-header {
  padding-bottom: 5px;
}

div.title-rectangle {
  height: 130px;
  width: 280px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
}

.input-field {
  height: 35px;
  width: 261px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 3px 2px 0 2px #4da1ff;
}

/*--------------------------------------------------------------
# Widget
--------------------------------------------------------------*/

.widget-row-container {
  padding: 20px;
  background: #fff;
  border: 1px solid #ebeaea;
  border-top: none;
  transition: background 1s ease-in-out 1s;
  width: 100%;
}

.widget-row-body-bold {
  font-size: 12px;
}

.widget-row-body {
  font-size: 12px;
}

/*--------------------------------------------------------------
# Misc
--------------------------------------------------------------*/

i.pipe {
  padding: 0 12px;
  font-style: normal;
}

.red-outstanding {
  color: #ff0000;
  padding-top: 4px;
}

.red {
  color: #ff0000;
}

p.green {
  color: green;
}
p.orange {
  color: #e2a22e;
}
p.red {
  color: red;
}
p.blue {
  color: #2579a9;
}
