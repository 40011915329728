.company-panel-list .list-table {
	background: #fff!important;
}

.list-company-row {
	padding-top: 0;
	padding-bottom: 0;
	line-height: 75px;
	height: 75px;
	background: #fff;
	border-bottom: 1px solid #ebeaea;
	margin-bottom: 0px;
	padding-left: 30px;
	position: relative;
	font-weight: 400;
}

.list-company-row .btn-edit-note {
	position: absolute;
	display: none;
	right: 0;
	top: 33px;
	padding: 6px;
	line-height: 1em;
	background: transparent;
	right: 14px;
	color: #787878;
	outline: none;
}

.list-company-row:hover {
	background-color: #f7f7f7;
	transition: all 300ms;
}

	.list-company-row:hover .btn-edit-note {
		display: block;
	}

	.list-header {
		padding: 10px 0 10px 30px;
		border-bottom: 1px solid #ebeaea;
	}
