.tippy-box[data-theme~="basic"] {
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}

/* border */
.tippy-box[data-theme~="basic"] > .tippy-svg-arrow > svg:first-child {
  fill: #ebeaea;
}

/* fill */
.tippy-box[data-theme~="basic"][data-placement^='left'] > .tippy-svg-arrow > svg:last-child {
  fill: #ffffff;
  margin-left: -1px;
}

.tippy-box[data-theme~="basic"][data-placement^='right'] > .tippy-svg-arrow > svg:last-child {
  fill: #ffffff;
  margin-right: -1px;
}
