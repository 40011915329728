.stats-container {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 90%;
}

.chart-container {
  background-color: #ffffff;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  width: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 991px) {
  .stats-container {
    display: block;
    width: 100%;
	margin: 0;
  }

  .chart-container {
    width: 98%;
	padding: 10px;
	margin: 10px 10px 10px 5px;
  }
}
