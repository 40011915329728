.container-page-header.company-header h2 {
  font-size: 24px;
  line-height: 28px;
  float: left;
  color: #000;
}

.company-actions {
  float: left;
}

.company-actions ul {
  list-style: none;
}

.company-actions ul li {
  float: left;
  margin: 0 5px;
}

.company-actions .tv-button {
  margin-top: 0px;
}

.company-profile {
  padding-top: 40px;
}

.company-profile .sidebar-container.loading {
  min-height: 840px;
}

.company-profile .col-md-3,
.company-profile .col-md-6 {
  padding-left: 10px;
  padding-right: 10px;
}

.col-md-3.left-sidebar {
  padding-left: 50px;
  margin-bottom: 50px;
}

.col-md-3.right-sidebar {
  padding-right: 50px;
  margin-bottom: 50px;
}

.company-profile .content-container {
  border: 0 none;
  border-bottom: 1px solid #ebeaea;
  border-radius: 0;
  margin: 0;
  background: none;
  padding: 24px;
  width: 100%;
}

.company-profile .content-container:last-child {
  border-bottom: 0 none;
}

.company-profile h2 {
  font-weight: 600;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.company-profile h3 {
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 15px;
  color: #333;
}

.company-profile label {
  color: #898989;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 6px;
  display: block;
  font-weight: 600;
}

.company-error-fallback-mobile {
  margin-top: 48px;
  position: relative;
  padding: 24px;
  height: calc(100vh - 108px);
}

.company-error-fallback-mobile .sidebar-container {
  height: 100%;
  text-align: center;
}

.prospects-block .tv-select-container {
  margin-bottom: 6px;
}

.company-content-text {
  font-size: 13px;
}

.company-info {
  font-size: 13px;
  color: #000;
}

.card-block.company-info label {
  color: #898989;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 6px;
  display: block;
  font-weight: 600;
}

.company-info li {
  margin-bottom: 18px;
}

.company-info .funding-toggle-button {
  float: right;
}

.social-media-icons {
  display: flex;
  margin-top: 10px;
}

.social-media-icons .fa-share-square-o {
  /*vertical-align: text-bottom;*/
}

.company-info i.fa {
  font-size: 15px;
  margin-right: 6px;
  color: #ccc;
}

.company-score {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  padding: 7px 14px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
}

.company-score:hover .vertical-item.empty {
  /* border: 1px solid #ccc; */
}

.company-score:hover .vertical-item {
  cursor: pointer;
}

.company-score label {
  text-align: left;
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 8px;
  margin-right: 10px;
  color: #9b9b9b;
  font-weight: 500;
}

.company-score span {
  font-size: 18px;
  font-weight: 600;
  color: #555;
}

.company-score span.vertical-box {
  margin-right: -5px;
}

.company-score .none {
  color: #000000;
}

.company-score .tier3 {
  color: #f45342;
}

.company-score .tier2 {
  color: #f4eb41;
}

.company-score .tier1 {
  color: #236700;
}

.company-score .low {
  color: #f45342;
}

.company-score .medium {
  color: #f4eb41;
}

.company-score .high {
  color: #236700;
}

.company-score button {
  background: 0 none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.investor-search .company-score button {
  display: block;
}

.company-feed {
  position: relative;
}

.company-feed .filter-container {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.company-feed textarea.form-control {
  min-height: 83px;
  border: 1px solid #dcdcdc;
  border-radius: 4px !important;
  background-color: #ffffff;
  box-shadow: none;
  font-size: 12px;
  width: 100%;
  display: block;
  margin-bottom: 8px;
}

.company-feed .feed-header {
  margin-bottom: 5px;
  height: 28px;
}

.company-feed .feed-actions {
  position: absolute;
  right: 0;
  top: 0px;
  padding: 0;
  width: 100%;
}

.company-feed .feed-actions button {
  margin-left: 10px;
}

.company-feed .feed-actions > div {
  float: right;
}

.company-feed .feed-actions .subscribe-button-container {
  float: left;
  margin-left: 0;
}

.company-feed .feed-actions .subscribe-button-container button {
  margin-left: 0;
}

.feed-legend {
  height: 28px;
  line-height: 27px;
  padding: 0 20px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin-bottom: 22px;
  font-size: 12px;
  overflow: hidden;
}

.feed-legend label {
  float: left;
  color: #898989;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 300;
}

.feed-legend ul {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.feed-legend ul li {
  float: left;
  position: relative;
  padding-left: 44px;
  font-size: 11px;
}

.feed-legend ul li a:hover {
  text-decoration: none;
  color: #464646;
}

.feed-legend ul li a:before {
  content: ' ';
  position: absolute;
  left: 26px;
  top: 50%;
  margin-top: -6px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.feed-legend ul li a.active:before {
  border: 0 none;
  background-image: url('../../images/white-checkmark.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;
}

.company-feed .list-group {
  padding: 0;
  margin-left: 3px;
  border-radius: 0 !important;
}

.feed-icon:before {
  content: ' ';
  position: absolute;
  left: -4px;
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  background-color: #ddd;
}

a.active.feed-icon-note:before,
.feed-icon-note:before {
  background-color: #f5a623;
}

a.active.feed-icon-task:before,
.feed-icon-task:before {
  background-color: #50e3c2;
}

a.active.feed-icon-news:before,
.feed-icon-news:before {
  background-color: #f8e71c;
}

a.active.feed-icon-funding:before,
.feed-icon-funding:before {
  background-color: #4a90e2;
}

a.active.feed-icon-status:before,
.feed-icon-status:before {
  background-color: #8b572a;
}

a.active.feed-icon-list:before,
.feed-icon-list:before {
  background-color: #6400ff;
}

a.active.feed-icon-all:before,
.feed-icon-all:before {
  background-color: #000000;
}

.company-feed .list-group-item {
  padding: 0 20px 18px;
  border: 0;
  border-left: 1px solid #ddd;
}

.company-feed .list-group-item:last-child {
  border-left: 0 none;
}

.list-group-item .media-body {
  margin-top: -5px;
}

.media-body p {
  color: #424242;
  font-size: 13px;
  margin: 6px 0 8px;
}

.feed-heading-text {
  font-size: 11px;
  font-weight: 500;
  display: block;
  color: #898989;
}

.note-actions {
  display: block;
  text-align: right;
  margin-top: 6px;
}

label.btn-file {
  display: inline-block;
  color: #323232;
  text-transform: none;
  padding: 0 13px;
  font-size: 11px;
  text-align: center;
  margin-right: 12px;
  cursor: pointer;
}

.mobile-profile-nav {
  display: none;
  line-height: 30px;
  border-bottom: 2px solid #eee;
  margin-bottom: 15px;
}

.mobile-profile-nav ul {
  list-style: none;
  width: 100%;
  margin: 0;
  height: 30px;
  display: block;
  padding: 0 0 0 10px;
}

.mobile-profile-nav li {
  float: left;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: -2px;
  position: relative;
}

.mobile-profile-nav button {
  border: 0 none;
  background: transparent;
  padding: 0;
  outline: none !important;
}

.mobile-profile-nav li.active {
  font-weight: 500;
}

.mobile-profile-nav li.active:after {
  content: ' ';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  background-color: #333;
  left: 0;
}

.ql-editor {
  min-height: 200px;
}

.company-profile .row.scroll-container {
  padding-bottom: 100px;
}

@media only screen and (max-width: 991px) {
  .mobile-overview {
    display: block;
  }

  .mobile-feed {
    display: none;
  }

  .container.company-profile {
    padding-top: 80px;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .container.company-profile.investor-profile {
    padding-top: 120px !important;
  }

  .company-panel-feed .list-table {
    margin-top: 0 !important;
  }

  .company-panel-list.investor-list .list-table {
    margin-top: 0 !important;
  }

  .company-profile .row.scroll-container {
    margin-right: -15px;
    margin-left: -15px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    top: 160px;
    margin-bottom: 140px;
    padding-bottom: 200px;
  }

  .mobile-profile-nav {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 1000;
    left: 0;
    top: 157px;
    background-color: #f7f7f7;
    padding: 15px 15px 15px 6px;
  }

  .mobile-profile-nav.investor-page {
    top: 115px;
  }

  .container-page-header.company-header {
    height: fit-content !important;
    position: fixed;
    justify-content: space-between;
    align-items: center;
  }

  .container-page-header.company-header h2 {
    font-size: 22px;
    line-height: 26px;
  }

  .company-actions {
    padding: 0;
  }

  .company-actions button {
    min-width: 30px;
  }

  .company-actions ul {
    padding: 0;
    margin: 0;
  }

  .company-actions .tv-button {
    margin: 3px 0;
  }

  .company-profile {
    padding: 15px;
  }

  .hidden-mobile {
    display: none !important;
  }

  .feed-legend {
    display: none;
  }

  .col-md-3 {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .col-md-3.left-sidebar {
    display: flex;
    padding: 0 15px;
    width: 100%;
  }

  .sidebar-container {
    width: 100%;
  }

  .col-md-6 {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .company-feed {
    padding: 0 5px;
    width: 100%;
  }

  .col-md-3.right-sidebar {
    padding: 0 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .container-page-header.company-header {
    padding: 12px;
    position: fixed;
  }
}
