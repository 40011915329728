.tv-company-modal.documents-modal .modal-dialog {
  max-width: 75%;
}

.documents-modal .modal-body {
  height: 80vh;
}

.icon-button {
  padding: 0 !important;
  margin: 0 0 0 10px;
  background: none;
  color: grey;
  border: none;
}

.icon-button .fa-trash {
  top: -0.6px;
  position: relative;
}

.icon-button .fa-trash.with-margin {
  margin-left: 4px;
}

.list-spaced .document-li {
  font-size: 13.5px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.list-spaced .document-li a {
  word-break: break-all;
}

.list-spaced .document-li .document-date-added {
  font-size: 12px;
  margin-top: 2px;
  color: #9b9b9b;
}

.company-documents label {
  cursor: pointer;
}

@media only screen and (max-width: 720px) {
  .documents-modal .modal-body {
    height: 90vh;
  }
  .tv-company-modal.documents-modal .modal-dialog {
    max-width: unset;
    width: 96%;
  }
}
