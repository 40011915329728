.companyHovercard__container {
  width: 350px;
}

.content-container.companyHovercard__container {
  width: 350px;
  min-height: 570px;
  padding: 20px 20px 30px 20px;
}

.hovercard-grid {
  columns: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
}

.hovercard-actions {
  display: flex;
  gap: 6px;
  margin-top: 12px;
  margin-bottom: -30px;
}

.companyHovercard__container .list-unstyled .list-spaced .hovercard-grid li {
  margin-bottom: 0;
}

.hovercard-actions .tv-button {
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #eeedec;
  border-radius: 15px;
  height: 32px;
  font-size: 16px;
  min-width: 70px;
}
