.watchlistPanel__name {
  width: 50%;
  max-height: 2.4em !important;
  line-height: 1.2em !important;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
}

.watchlistPanel__vertical {
  height: 24px;
  width: 24px;
  line-height: 24px;
  padding: 0 4px;
  border-radius: 2px;
  display: inline-block;
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  background-color: #fff;
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 4px;
  border: 1px solid #ebeaea;
}
