.company-feed-item-container {
  padding: 24px;
  background: #fff;
  /* border-radius: 4px; */
  /* box-shadow: 0 2px 1px 0 rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%); */
  /* margin-bottom: 10px; */
  position: relative;
  /* font-weight: 500; */
  border-bottom: 1px solid #ebeaea;
}

.company-feed-item-date {
  color: #a9a9a9;
  margin-left: 5px;
}

.company-feed-item-header {
  display: flex;
  font-size: 14px;
  line-height: 1.2em;
}

.company-feed-item-name {
  white-space: nowrap;
  white-space: initial;
  line-height: 1;
}

.company-feed-item-name.is-mobile {
  padding-right: 0;
}

.company-feed-item-name a {
  color: #000;
}

.company-feed-item-type {
  padding-right: 5px;
}

.company-feed-item-type.note {
  color: #a24a4a;
}

.company-feed-item-type.status {
  color: #a24aa1;
}

.company-feed-item-type.news {
  color: #a2724a;
}

.company-feed-item-type.funding {
  color: #4a65a2;
}

.company-feed-item-body {
  font-weight: 400;
  line-height: 16px;
  font-size: 14px;
  margin-top: 8px;
  width: 100%;
  position: relative;
}
