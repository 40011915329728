.company-financials {
  padding: 24px 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 1px 0 rgb(0 0 0 / 8%);
  -webkit-box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
  margin-bottom: 20px;
  position: relative;
  font-weight: 400;
  border: 1px solid #ebeaea;
}

.financials-btns {
  background: #f2f2f2;
  border-radius: 4px;
  height: 40px;
  padding: 3px;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #ebeaea;
}

.financials-btns .btn {
  border: 0 none;
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
  font-size: 14px;
  color: #6e6e6e;
  font-weight: 400;
  width: 50%;
}

.financials-btns .btn.active {
  border: 0;
  background-color: #fff;
  box-shadow: none;
  -webkit-box-shadow: none;
  font-weight: 500;
  color: #333;
  border-radius: 4px !important;
}
