.dashboard-container {
  /* display: flex; */
  /* justify-content: flex-start; */
  /* flex-direction: row; */
  align-items: stretch;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  /* gap: 10px; */
  padding: 0 20px;
  margin-top: 40px;
}

.dashboard-container.is-mobile {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

.feed {
  width: calc(100% - 20px);
  position: relative;
  overflow: hidden;
}

.dashboard-container .company-panel-feed {
  width: 100%;
  padding-bottom: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
}

.sidebar {
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
}

.sidebar.left {
  margin-right: 10px;
  width: calc(100% - 10px);
}

.sidebar.right {
  margin-left: 10px;
  width: calc(100% - 10px);
}

@media only screen and (max-width: 991px) {
  .sidebar.left {
    width: 100%;
    margin: 0;
  }
  .sidebar.right {
    margin: 0;
    width: 100%;
  }
}

.section-header.widget {
  padding: 20px;
  margin: 0px !important;
  background: #fff;
  width: 100%;
  border-top: 1px solid #ebeaea;
  border-left: 1px solid #ebeaea;
  border-right: 1px solid #ebeaea;
  border-radius: 4px 4px 0 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header.widget h6 {
  margin: 0;
  font-size: 18px;
}

.tv-button.see-more-btn {
  margin: 20px auto;
  margin-bottom: 20px;
  color: #000;
  border-radius: 14px;
  font-weight: 400;
  display: block;
}

.dashboard-container .feed .user-feed {
  overflow-y: scroll;
  position: relative;
}

.dashboard-container .feed .user-feed .filter-container {
  border-radius: unset;
  box-shadow: none;
  display: flex;
  border-bottom: none;
  overflow-x: scroll;
}

@media only screen and (max-width: 991px) {
  .feed {
    position: relative;
    padding-top: 30px;
    top: 0;
    width: 100%;
    margin-right: 0;
  }

  .sidebar {
    position: relative;
    padding-top: 30px;
    top: 0;
    width: 100%;
    background: #f7f7f7;
    overflow: hidden;
  }

  .hidden-mobile {
    display: none !important;
  }

  .section-header {
    margin: 5px !important;
  }
}

.dashboard-error,
.page-loader {
  height: calc(100vh - 200px);
  max-height: 840px;
}

.dashboard-error button {
  margin-top: 40px;
}
